import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import '../footer/style.scss'

const Footer = ({ siteTitle }) => (
  <footer style={{ marginBottom: '70px' }}>
    <div className="footer1 footer2">
      <a
        className="link"
        target="_blank"
        rel="noopener noreferrer"
        href="https://lackrecords.bandcamp.com"
      >
        Bandcamp
      </a>
      |
      <a
        className="link"
        target="_blank"
        rel="noopener noreferrer"
        href="https://clone.nl/all/label/lackrec"
      >
        Clone
      </a>
      |
      <a
        className="link"
        rel="noopener noreferrer"
        target="_blank"
        href="https://soundcloud.com/lackrec"
      >
        SoundCloud
      </a>
    </div>
    <div className="footer1">
      <Link className="link" to="/imprint">
        Impressum
      </Link>
      |
      <Link className="link" to="/data">
        Data Security
      </Link>
    </div>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
